import { z } from 'zod';

export enum Platform {
  WEB = 'WEB',
  DESKTOP = 'DESKTOP'
}

export const VoiceTokenRequestSchema = z.object({
  platform: z.nativeEnum(Platform),
  applicationId: z.string(),
  applicationVersion: z.string()
});

export type VoiceTokenRequest = z.infer<typeof VoiceTokenRequestSchema>;
